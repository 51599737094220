// config.js
const config = {
    //apiUrl: 'http://localhost:3001',
    apiUrl: 'https://cargozhibekzholy.kz:3001',
    
    nameCargo: 'Cargo Zhibek Zholy'
  };
  
  
export default config;
  