// ChangePassword.js
import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import { Link, useNavigate } from 'react-router-dom';

const ChangePassword = () => {
    const [confirmationCode, setConfirmationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [phone, setPhone] = useState("");
    const navigate  = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.apiUrl}/api/auth/change-password`, {
                phone: phone,
                confirmationCode,
                newPassword,
            })
            setMessage(response.data);
            navigate("/login");
        } catch (error) {
            setMessage(error.response?.data?.message || 'Ошибка при смене пароля');
        }
    };

    const [showPasswordChange, setShowPasswordChange] = useState(false); 

    const handleSubmitRequest = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.apiUrl}/api/auth/request-password-change`, { phone: phone })
            console.log(response.status) 
            //setMessage(response?.message);
            setShowPasswordChange(true)
        } catch (error) {
            setShowPasswordChange(false)
            console.log(error);
            setMessage(error.response?.data?.message || 'Ошибка отправки формы');
        }
    };

    return (

        <div className="auth">
            <h1 className="h1-auth">Смена пароля</h1>
            
             { showPasswordChange ? 
             <div>
                <div>
                    <h2>Смена пароля</h2>
                    <form className="form" onSubmit={handleSubmit}>
                        <div className="input__div">
                            <input
                                type="text"
                                value={confirmationCode}
                                onChange={(e) => setConfirmationCode(e.target.value)}
                                placeholder="Введите код подтверждения"
                                className="input"
                                required
                            />
                        </div>
                        <div className="input__div">
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Введите новый пароль"
                                className="input"
                                required
                            />
                        </div>
                        <br />
                        <button type="submit" class="logout">Сменить пароль</button>
                    </form>
                </div>
            </div> 
            : 
            <form className="form" onSubmit={handleSubmitRequest}>
                <input value={phone} onChange={(event) => setPhone(event.target.value)} setValue={setPhone} type="number" className="input" placeholder="Номер телефона"/>
                <button type="submit" class="buttonLogin">Отправить код</button>
            </form>
            }
            
            {message && <p className="error-text">{message}</p>}

            <Link to="/login" className="link__auth">Войти</Link>
            
        </div>

        
        
    );
};

export default ChangePassword;
