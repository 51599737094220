import React, { useState, useEffect } from "react";
import { Link, useNavigate} from 'react-router-dom';
import './styles/home.css';
import phonePNG from '../assets/img/phone.png';
import passwdPNG from '../assets/img/passwd.png';
import namePNG from '../assets/img/name.png';
import { registration } from "../action/user";
import { useDispatch } from 'react-redux'; // Импортируем useDispatch
import axios from 'axios';
import config from '../config';

const Registration = () => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");

    const navigate  = useNavigate(); // Инициализируем useHistory
    
    const dispatch = useDispatch(); // Получаем dispatch

    const [confirmPhone, setConfirmPhone] = useState(false); 

    const [code, setCode] = useState(''); 

    const [message, setMessage] = useState('');

    const handleRegistration = async () => {

        const registrationSuccess = await dispatch(registration(name, surname, phone, password));
        if (registrationSuccess) {
          setConfirmPhone(true)
        }
    };

    const handleRegistrationCode = async () => {
      try {
        const response = await axios.post(`${config.apiUrl}/api/auth/confirm-phone`, {
            phone: phone,
            confirmationCode:code
        })
        setMessage(response.data);
        navigate("/main");
      } catch (error) {
        setMessage(error.response?.data?.message || 'Ошибка при подтверждении телефона');
      }
    }

    const handleRequestCode = async () => {
      try {
        const response = await axios.post(`${config.apiUrl}/api/auth/request-confirm-code`, {
            phone: phone,
        })
        setMessage('Код отправлен');
      } catch (error) {
        setMessage(error.response?.data?.message || 'Ошибка при запросе кода');
      }
    }

    useEffect(() => {
      console.log('confirmPhone', confirmPhone);
    }, [])

    return (
      <div className="auth">
      
      {
        confirmPhone == false ?
        <div className="form">
          <h1 className="h1-auth">Регистрация</h1>
          <div className="input__div"><img src={namePNG} alt="person" className="phonePNG"/>
            <input value={name} onChange={(event) => setName(event.target.value)} setValue={setName} type="text" className="input" placeholder="Имя"/>
          </div>
          <div className="input__div"><img src={namePNG} alt="person" className="phonePNG"/>
            <input value={surname} onChange={(event) => setSurname(event.target.value)} setValue={setSurname} type="text" className="input" placeholder="Фамилия"/>
          </div>
          <div className="input__div"><img src={phonePNG} alt="Phone" className="phonePNG"/>
            <input value={phone} onChange={(event) => setPhone(event.target.value)} setValue={setPhone} type="number" className="input" placeholder="Номер телефона"/>
          </div>
          <div className="input__div"><img src={passwdPNG} alt="Password" className="phonePNG"/>
            <input value={password} onChange={(event) => setPassword(event.target.value)} setValue={setPassword} type="password" className="input" placeholder="Придумайте пароль"/>
          </div>
          {/* <div className="input__div"><img src={passwdPNG} alt="Password" className="phonePNG"/>
            <input type="password" className="input" placeholder="Повторите пароль"/>
          </div> */}
          
          <button className="buttonLogin" onClick={handleRegistration}>Зарегестрироваться</button>
          <Link to="/login" className="link__auth">Войти</Link>
        </div>        
        :
        <div className="form">
          <h1 className="h1-auth">Введите код, отправленный на номер: {phone} </h1>
          <div className="input__div"><img src={phonePNG} alt="Phone" className="phonePNG"/>
            <input value={code} onChange={(event) => setCode(event.target.value)} setValue={setCode} type="number" className="input" placeholder="Код подтверждения"/>
          </div>

          {message && <p className="error-text">{message}</p>}

          <button className="buttonLogin" onClick={handleRegistrationCode}>Подтвердить</button>
          <button className="buttonLogin" onClick={handleRequestCode}>Запросить код повторно</button>          
          <Link to="/main" className="link__auth">Пропустить</Link>
        </div>

      }

      
    </div>

    )
}

export default Registration;