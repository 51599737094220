// ChangePassword.js
import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';

const ConfirmPhone = ({ user, showPasswordChange, setIsConfirmed }) => {
    const [confirmationCode, setConfirmationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.apiUrl}/api/auth/confirm-phone`, {
                phone: user.phone,
                confirmationCode
            })
            setMessage(response.data);
            showPasswordChange(false);
            setIsConfirmed(true);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Ошибка при подтверждении телефона');
        }
    };

    const [phone, setPhone] = useState("");

    return (

        <div>
            <h1 className="h1-auth">Введите код подтверждения</h1>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="input__div">
                            <input
                                type="text"
                                value={confirmationCode}
                                onChange={(e) => setConfirmationCode(e.target.value)}
                                placeholder="Введите код подтверждения"
                                className="input"
                                required
                            />
                        </div>
                        <br />
                        <button type="submit" class="logout">Подтвердить телефон</button>
                    </form>
                </div>

            {message && <p className="error-text">{message}</p>}            
        </div>
    );
};

export default ConfirmPhone;
