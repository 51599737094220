import React, { useEffect, useState, useCallback,  useRef } from "react";
import './css/admin.css';
import Title from "./title";
import circleStatus from "../../assets/img/circleStatus.png";
import tableList from "../../assets/img/tableList.png";
import scan from "../../assets/img/scan.png";
import { getStatus } from "../../action/status";
import { addTrack, excelTracks} from "../../action/track";
import loadingPNG from "../../assets/img/loading.png";
import check from "../../assets/img/check.png";
import { useSelector } from "react-redux";
import { fetchFilialByUserPhone } from "../../action/filial";
import applepay from "../../assets/sound/applepay.mp3";
import error404 from "../../assets/sound/error-404.mp3";

const successAudio = new Audio(applepay);
const errorAudio = new Audio(error404);

const AddTrack = () => {
    const [statuses, setStatuses] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [status, setStatus] = useState();
    const [track, setTrack] = useState('');
    const [date, setDate] = useState();
    const [textareaValue, setTextareaValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [statusText, setStatusText] = useState("Выберите статус");
    const [filial, setFilial] = useState();
    const inputRef = useRef(null);

    const role = localStorage.getItem('role');

    const playSuccessSound = () => {
        successAudio.play();
    };

    const playErrorSound = () => {
        errorAudio.play();
    };

    const resetSuccess = () => {
        setTimeout(() => {
          setSuccess(false);
        }, 1500);
    };

    const phone = useSelector(state => state.user.currentUser.phone);

    const fetchStatuses = async () => {
        try {
            const statusesData = await getStatus();
            setStatuses(statusesData);
            console.log(statusesData);
        } catch (error) {
            console.error('Ошибка при получении статусов:', error);
        }
    };

    const getFilial = useCallback(async () => {
        try {
            const filialData = await fetchFilialByUserPhone(phone);
            setFilial(filialData);
            console.log(filialData);
        } catch (error) {
            console.log(error.message);
        }
    }, [phone]);

    useEffect(() => {
        // Получаем статусы при загрузке компонента
        fetchStatuses();
        getFilial();
    }, [phone, getFilial]);

    const handleStatusClick = (statusId, statusText) => {
        setStatus(statusId);
        setStatusText(statusText);
        setIsPopupOpen(false);
    };

    const handleDateChange = (event) => {
        const newDate = event.target.value; // Получаем новое значение из поля ввода
        const formattedDate = new Date(newDate).toISOString().slice(0, 10); // Преобразуем дату и обрезаем до 10 символов
        setDate(formattedDate); // Устанавливаем новое значение в состояние
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleOpenModal = () => {
        setModalOpen(!modalOpen);
    };

    const handlePasteAndDropMulty = async (event) => {
        if (event.type === 'drop')  event.preventDefault();
    
        const target = event.target;

        const transfer = event.clipboardData || event.dataTransfer;

        console.log('transfer', transfer.getData('text'));
        //setTrack(transfer.getData('text'))
        setTextareaValue(textareaValue + transfer.getData('text') + '\n')

        return false;
    }

    const handlePasteAndDrop = async (event) => {
        if (event.type === 'drop')  event.preventDefault();
    
        const target = event.target;

        const transfer = event.clipboardData || event.dataTransfer;

        console.log('transfer', transfer.getData('text'));
        setTrack(transfer.getData('text'))

        return false;
    }

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            setLoading(true);

            try {
                const newTrack = track.replace(/\s/g, '');

                // Проверка на пустые значения
                if (!status || !date || !newTrack.trim()) {
                    playErrorSound();
                    setLoading(false);
                    return alert('Необходимо заполнить все поля');
                }

                await addTrack(newTrack, status, date);
                setTrack('');
                setSuccess(true); // Устанавливаем флаг успешной загрузки
                playSuccessSound();
            } catch (error) {
                console.error('Ошибка при обновлении треков:', error);
                playErrorSound();
                alert(error.response.data.message); // Выводим сообщение об ошибке
            } finally {
                setLoading(false); // Сбрасываем флаг загрузки после завершения запроса
            }
        }
        resetSuccess();
    };

    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        // Проверка на пустые значения
        if (!status || !date || !textareaValue.trim()) {
            playErrorSound();
            setLoading(false);
            return alert('Необходимо заполнить все поля');
        }

        const trackList = textareaValue
            .split('\n') // Разбиваем текст на строки
            .filter(track => track.trim() !== '') // Удаляем пустые строки
            .map(track => track.trim()); // Убираем пробелы из начала и конца каждой строки

        console.log(trackList);
        // Отправляем запрос на обновление треков
        try {
            await excelTracks(trackList, status, date);
            console.log('Треки успешно обновлены!');
            setSuccess(true); // Устанавливаем флаг успешной загрузки
        } catch (error) {
            playErrorSound();
            console.error('Ошибка при обновлении треков:', error);
            alert(error.response?.data?.message || 'Ошибка при запросе кода'); // Выводим сообщение об ошибке
        } finally {
            setLoading(false); // Сбрасываем флаг загрузки после завершения запроса
            setTextareaValue('');
            handleOpenModal();
        }
        resetSuccess();
    };

    const onKeyDownFunction = async (e, type = 'one') => {
        let curr = '';
        if (type == 'one') {
            curr = track ? track : '';
        } else {
            curr = textareaValue ? textareaValue : '';
        }

        if (e.ctrlKey && e.keyCode === 67) {
            console.log('You pressed Ctrl + C!');
            return;
            // Add your logic for Ctrl + C here
        } else if (e.ctrlKey && e.keyCode === 86) {
            console.log('You pressed Ctrl + V!', e);
            //const data = e.dataTransfer.getData("text");
            
                // Update the value
            //curr = data;
            
            // Add your logic for Ctrl + V here
            return;
        } else if (e.ctrlKey && e.keyCode === 65) {
            console.log('You pressed Ctrl + A!');
            return;
        }

        switch (e.keyCode) {
            case 8:
                console.log('You pressed Backspace!');
                let charArray = curr.split('');

                // Pop the last character from the array
                charArray.pop();

                // Join the array back into a string
                curr = charArray.join('');

                break;
            case 9:
                console.log('You pressed Tab!');
                break;
            case 13:
                console.log('You pressed Enter!');
                if (type == 'one') {
                    setLoading(true);

                    try {
                        const newTrack = track.replace(/\s/g, '');

                        // Проверка на пустые значения
                        if (!status || !date || !newTrack.trim()) {
                            setLoading(false);
                            playErrorSound();
                            return alert('Необходимо заполнить все поля');
                        }

                        await addTrack(newTrack, status, date);
                        curr = '';
                        setTrack('');
                        playSuccessSound();
                        setSuccess(true); // Устанавливаем флаг успешной загрузки
                    } catch (error) {
                        console.error('Ошибка при обновлении треков:', error);
                        playErrorSound();
                        alert(error.response.data.message); // Выводим сообщение об ошибке
                    } finally {
                        setLoading(false); // Сбрасываем флаг загрузки после завершения запроса
                    }
                    resetSuccess();
                } else {
                    curr = curr + '\n';
                }
                break;
            case 16:
                console.log('You pressed Shift!');
                break;
            case 17:
                console.log('You pressed Control!');
                break;
            case 18:
                console.log('You pressed Alt!');
                break;
            case 19:
                console.log('You pressed Pause/Break!');
                break;
            case 20:
                console.log('You pressed Caps Lock!');
                break;
            case 27:
                console.log('You pressed Escape!');
                break;
            case 32:
                console.log('You pressed Spacebar!');
                break;
            case 33:
                console.log('You pressed Page Up!');
                break;
            case 34:
                console.log('You pressed Page Down!');
                break;
            case 35:
                console.log('You pressed End!');
                break;
            case 36:
                console.log('You pressed Home!');
                break;
            case 37:
                console.log('You pressed Left Arrow!');
                break;
            case 38:
                console.log('You pressed Up Arrow!');
                break;
            case 39:
                console.log('You pressed Right Arrow!');
                break;
            case 40:
                console.log('You pressed Down Arrow!');
                break;
            case 48:
                console.log('You pressed 0!');
                curr = curr + '0';
                break;
            case 49:
                curr = curr + '1';
                console.log('You pressed 1!');
                break;
            case 50:
                curr = curr + '2';
                console.log('You pressed 2!');
                break;
            case 51:
                curr = curr + '3';
                console.log('You pressed 3!');
                break;
            case 52:
                curr = curr + '4'
                console.log('You pressed 4!');
                break;
            case 53:
                curr = curr + '5'
                console.log('You pressed 5!');
                break;
            case 54:
                curr = curr + '6'
                console.log('You pressed 6!');
                break;
            case 55:
                curr = curr + '7'
                console.log('You pressed 7!');
                break;
            case 56:
                curr = curr + '8'
                console.log('You pressed 8!');
                break;
            case 57:
                curr = curr + '9'
                console.log('You pressed 9!');
                break;
            case 65:
                curr = curr + 'A'
                console.log('You pressed A!');
                break;
            case 66:
                curr = curr + 'B'
                console.log('You pressed B!');
                break;
            case 67:
                curr = curr + 'C'
                console.log('You pressed C!');
                break;
            case 68:
                curr = curr + 'D'
                console.log('You pressed D!');
                break;
            case 69:
                curr = curr + 'E'
                console.log('You pressed E!');
                break;
            case 70:
                curr = curr + 'F'
                console.log('You pressed F!');
                break;
            case 71:
                curr = curr + 'G'
                console.log('You pressed G!');
                break;
            case 72:
                curr = curr + 'H'
                console.log('You pressed H!');
                break;
            case 73:
                curr = curr + 'I'
                console.log('You pressed I!');
                break;
            case 74:
                curr = curr + 'J'
                console.log('You pressed J!');
                break;
            case 75:
                curr = curr + 'K'
                console.log('You pressed K!');
                break;
            case 76:
                curr = curr + 'L'
                console.log('You pressed L!');
                break;
            case 77:
                curr = curr + 'M'
                console.log('You pressed M!');
                break;
            case 78:
                curr = curr + 'N'
                console.log('You pressed N!');
                break;
            case 79:
                curr = curr + 'O'
                console.log('You pressed O!');
                break;
            case 80:
                curr = curr + 'P'
                console.log('You pressed P!');
                break;
            case 81:
                curr = curr + 'Q'
                console.log('You pressed Q!');
                break;
            case 82:
                curr = curr + 'R'
                console.log('You pressed R!');
                break;
            case 83:
                curr = curr + 'S'
                console.log('You pressed S!');
                break;
            case 84:
                curr = curr + 'T'
                console.log('You pressed T!');
                break;
            case 85:
                curr = curr + 'U'
                console.log('You pressed U!');
                break;
            case 86:
                curr = curr + 'V'
                console.log('You pressed V!');
                break;
            case 87:
                curr = curr + 'W'
                console.log('You pressed W!');
                break;
            case 88:
                curr = curr + 'X'
                console.log('You pressed X!');
                break;
            case 89:
                curr = curr + 'Y'
                console.log('You pressed Y!');
                break;
            case 90:
                curr = curr + 'Z'
                console.log('You pressed Z!');
                break;
            case 91:
                console.log('You pressed Left Window Key!');
                break;
            case 92:
                console.log('You pressed Right Window Key!');
                break;
            case 93:
                console.log('You pressed Select!');
                break;
            case 96:
                console.log('You pressed Numpad 0!');
                curr = curr + '0'
                break;
            case 97:
                console.log('You pressed Numpad 1!');
                curr = curr + '1'
                break;
            case 98:
                console.log('You pressed Numpad 2!');
                curr = curr + '2'
                break;
            case 99:
                console.log('You pressed Numpad 3!');
                curr = curr + '3'
                break;
            case 100:
                console.log('You pressed Numpad 4!');
                curr = curr + '4'
                break;
            case 101:
                console.log('You pressed Numpad 5!');
                curr = curr + '5'
                break;
            case 102:
                console.log('You pressed Numpad 6!');
                curr = curr + '6'
                break;
            case 103:
                console.log('You pressed Numpad 7!');
                curr = curr + '7'
                break;
            case 104:
                console.log('You pressed Numpad 8!');
                curr = curr + '8'
                break;
            case 105:
                console.log('You pressed Numpad 9!');
                curr = curr + '9'
                break;
            case 106:
                console.log('You pressed Numpad Multiply!');
                break;
            case 107:
                console.log('You pressed Numpad Add!');
                break;
            case 109:
                console.log('You pressed Numpad Subtract!');
                break;
            case 110:
                console.log('You pressed Numpad Decimal!');
                break;
            case 111:
                console.log('You pressed Numpad Divide!');
                break;
            case 112:
                console.log('You pressed F1!');
                break;
            case 113:
                console.log('You pressed F2!');
                break;
            case 114:
                console.log('You pressed F3!');
                break;
            case 115:
                console.log('You pressed F4!');
                break;
            case 116:
                console.log('You pressed F5!');
                break;
            case 117:
                console.log('You pressed F6!');
                break;
            case 118:
                console.log('You pressed F7!');
                break;
            case 119:
                console.log('You pressed F8!');
                break;
            case 120:
                console.log('You pressed F9!');
                break;
            case 121:
                console.log('You pressed F10!');
                break;
            case 122:
                console.log('You pressed F11!');
                break;
            case 123:
                console.log('You pressed F12!');
                break;
            case 144:
                console.log('You pressed Num Lock!');
                break;
            case 145:
                console.log('You pressed Scroll Lock!');
                break;
            case 186:
                console.log('You pressed Semi-colon!');
                break;
            case 187:
                console.log('You pressed Equal!');
                break;
            case 188:
                console.log('You pressed Comma!');
                break;
            case 189:
                console.log('You pressed Dash!');
                break;
            case 190:
                console.log('You pressed Period!');
                break;
            case 191:
                console.log('You pressed Forward Slash!');
                break;
            case 192:
                console.log('You pressed Grave Accent!');
                break;
            case 219:
                console.log('You pressed Open Bracket!');
                break;
            case 220:
                console.log('You pressed Backslash!');
                break;
            case 221:
                console.log('You pressed Close Bracket!');
                break;
            case 222:
                console.log('You pressed Single Quote!');
                break;
            default:
                console.log(`Key pressed: ${e.key} (Key Code: ${e.keyCode})`);
                break;
        }
        if (type == 'one') {
            setTrack(curr)
        } else {
            setTextareaValue(curr)
        }
        
        return false;
    }

    const handleKeyDownMul = async (e) => {
        onKeyDownFunction(e, 'multi')
    };

    const handleKeyDownCon = async (e) => {
        onKeyDownFunction(e)
    };

    const handleSubmitForm = (event) => {
        event.preventDefault();  // Prevents the form from submitting
        console.log('Form submitted, but prevented:');
        // You can add additional validation or logic here
    };

    return (
        <div className="mainAdmin">
            <Title text="Добавить трек"/>
            <div className="mainAdmin-container">
                {loading && <div className="loading modal-load"><img src={loadingPNG} alt="" /><p>Загрузка...</p></div>}
                {success && <div className="success modal-load"><img src={check} alt="" /><p>Успешно загружено!!</p></div>}
                <div className="mini-contianer">
                    <div className="status-excel-container">
                        <div className="date-container_mobile">
                            <h3 className="h3-date">Выберите дату</h3>
                            <input 
                                type="date" 
                                className="date-block" 
                                value={date} 
                                onChange={handleDateChange} 
                            />
                        </div>
                        
                        <div className="status-block" onClick={togglePopup}>
                            <p>{statusText}</p>
                            <img src={circleStatus} alt="" />
                        </div>
                        
                        {statuses
                            .filter(status => status.statusText !== "Получено" && role === 'filial')
                            .map(status => (
                                <div key={status._id} className="status-el status-pop" onClick={() => handleStatusClick(status._id, status.statusText)} >
                                    <p>{status.statusText}</p>
                                </div>
                            ))}
                            
                        {role === 'filial' && filial && filial.filialText &&
                            <div key={filial._id} className="status-el status-pop " onClick={() => handleStatusClick(filial._id, filial.filialText)} >
                                <p>{filial.filialText}</p>
                            </div>
                        }
                        <div className="statuses">
                            {statuses
                                .filter(status => status.statusText === "Получено" && role === 'filial')
                                .map(status => (
                                    <div key={status._id} className="status-el status-pop" onClick={() => handleStatusClick(status._id, status.statusText)} >
                                        <p>{status.statusText}</p>
                                    </div>
                                ))}
                            {role !== 'filial' && isPopupOpen && (
                                statuses.map(status => (
                                    <div key={status._id} className="status-el status-pop" onClick={() => handleStatusClick(status._id, status.statusText)} >
                                        <p>{status.statusText}</p>
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="excel-block" onClick={handleOpenModal}>
                            <p>Массовая загрузка</p>
                            <img src={tableList} alt="" />
                        </div>
                        {modalOpen && (
                            <div className="modalExcel">
                                <div className="modal-header">
                                    <h2>Массовая загрузка</h2>
                                    <div className="close" onClick={handleOpenModal}></div>
                                </div>
                                <form
                                    onPaste={handlePasteAndDropMulty}
                                    onDrop={handlePasteAndDropMulty}
                                    onSubmit={handleSubmitForm}
                                >
                                <textarea value={textareaValue} onKeyDown={handleKeyDownMul} name="textarea" id="" cols="30" rows="10" className="textarea"></textarea>
                                <button className="buttonExcel" onClick={handleSubmit}>Загрузить</button>
                                </form>
                            </div>
                        )}
                    </div>
                    <div className="date-container">
                        <h3 className="h3-date">Выберите дату</h3>
                        <input 
                            type="date" 
                            className="date-block" 
                            value={date} 
                            onChange={handleDateChange} 
                        />
                    </div>
                </div>    
                <form
                    onPaste={handlePasteAndDrop}
                    onDrop={handlePasteAndDrop}
                    onSubmit={handleSubmitForm}
                >
                <div className="scaner-block">
                    <input 
                        ref={inputRef}
                        type="text" 
                        className="scaner-input"
                        placeholder="Пробить сканером..."
                        value={track}
                        onKeyDown={handleKeyDownCon} 
                        
                    />
                    <img src={scan} alt="" />
                </div>
                </form>
            </div>
        </div>
    );
};

/*
if (event.type === 'drop')  event.preventDefault();
    
    const target = event.target;
    
    const cell = target.nextElementSibling.querySelectorAll('td');
    
    cell[0].textContent = event.type;

    const transfer = event.clipboardData || event.dataTransfer;
*/

export default AddTrack;
